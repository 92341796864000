import React from 'react';
import PropTypes from 'prop-types';

import { isNullish, isString } from '../../utils';
import styleTableBody from './utils/style-table-body';
import { columnsPropType, dataSourcePropType } from './utils/default-prop-types';

import { SortingArrows } from '../sorting_arrrows';
import TableEmptyMessage from './TableEmptyMessage';
import { Skeleton } from '../skeleton';

function UnStyledTableBody({ className, columns, currentItems, noDataMessage, isLoading, onClick, onSortAscend, onSortDescend }) {
    if (currentItems.length === 0 && !isLoading) return <TableEmptyMessage>{noDataMessage}</TableEmptyMessage>;

    return (
        <main className={className}>
            <table>
                <thead>
                    <tr>
                        {columns.map(({ key, title, dataIndex, format, sortable, style = {}, titleSize = '0.875rem' }) => (
                            <th key={key} style={style}>
                                <div className={key}>
                                    {isString(title) ? <span style={{ fontSize: titleSize }}>{title}</span> : title}
                                    {sortable && <SortingArrows onSortAscend={() => onSortAscend({ dataIndex, format })} onSortDescend={() => onSortDescend({ dataIndex, format })} />}
                                </div>
                            </th>
                        ))}
                    </tr>
                </thead>

                <tbody>
                    {isLoading
                        ? Array(10)
                              .fill({})
                              .map((item, itemIndex) => {
                                  return (
                                      <tr key={itemIndex}>
                                          {columns.map((column, columnIndex) => {
                                              return (
                                                  <td key={getCellKey(column, columnIndex)}>
                                                      <Skeleton />
                                                  </td>
                                              );
                                          })}
                                      </tr>
                                  );
                              })
                        : currentItems.map((item, itemIndex) => (
                              <tr key={itemIndex}>
                                  {columns.map((column, columnIndex) => (
                                      <td key={getCellKey(column, columnIndex)} onClick={() => onClick && !column.withoutClick && onClick(item.uuid, item)}>
                                          {displayCellContent(column, item)}
                                      </td>
                                  ))}
                              </tr>
                          ))}
                </tbody>
            </table>
        </main>
    );
}

function getCellKey(column, columnIndex) {
    return [column.key, columnIndex].join('-');
}

function displayCellContent(column, item) {
    const { dataIndex, render } = column;
    if (isNullish(dataIndex) && isNullish(render)) throw new Error('You must provide either <dataIndex> or <render> for each column');

    const value = item[dataIndex];
    if (Object.keys(item).includes(dataIndex)) {
        return render ? render(value, item.key, item) : value;
    }

    return render(value, item.key);
}

const StyledTableBody = styleTableBody(UnStyledTableBody);

export function TableBody({ columns = [], currentItems = [], isLoading = false, ...restProps }) {
    return <StyledTableBody columns={columns} currentItems={currentItems} isLoading={isLoading} {...restProps} />;
}
TableBody.propTypes = {
    columns: columnsPropType,
    currentItems: dataSourcePropType,
    isLoading: PropTypes.bool,
    onSortAscend: PropTypes.func.isRequired,
    onSortDescend: PropTypes.func.isRequired,
    onClick: PropTypes.func
};
